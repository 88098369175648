.success-toast {
  position: relative;
  width: 80px;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
  margin-top: 5px;
  background-color: var(--dcd-success) !important;
  border-radius: 5px 0 0 5px;
  min-height: 60px;
}

@media (min-width: 480px) {
  .success-toast {
    width: 150px;
    margin-right: -19px;
  }
}

